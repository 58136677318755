/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import './assets/styles.scss';
import { Outlet, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Docs from "./pages/docs";
import PageNotFound from "./pages/404";
import {Button} from "react-bootstrap";
import {AppRoutes} from "./AppRoutes";
import hljs from "highlight.js";
import siltext from "./highlightjs/siltext";
import './highlightjs/siltext.darcula.scss';
import {DocsService} from "./services/docs.service";
import CompareToNimbleText from "./pages/compare-to-nimble-text";
import {Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles} from "react-pro-sidebar";
import ScrollToHashElement from "./components/scrollToHash";

hljs.registerLanguage('siltext', siltext);

export const Globals =
    {
        AppTitle: process.env.REACT_APP_SITE_TITLE,
        Input: `Name, Years of Employment, Salary, Bonus
ellis, 6, 60.000, 0.20
samir, 10, 100.000, 0.15
max, 4, 55.000, 0.05
julia, 18, 96.000, 0.15`,
        Pattern: `Hi {{ $0|title }}, thanks for working with us for $4 years. Your bonus will be {{ ($5 * $6)|format('$0,0.00') }} this year.`,
        Output: `Hi Ellis, thanks for working with us for 6 years. Your bonus will be $12,000.00 this year.
Hi Samir, thanks for working with us for 10 years. Your bonus will be $15,000.00 this year.
Hi Max, thanks for working with us for 4 years. Your bonus will be $2,750.00 this year.
Hi Julia, thanks for working with us for 4 years. Your bonus will be $14,400.00 this year. `
    }

function App() {


    return (
        <>
            <ScrollToHashElement/>
            <Routes>
                <Route path={AppRoutes.home} element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path={AppRoutes.docs} element={<Docs/>}/>
                    <Route path={AppRoutes.compareToNimbleText} element={<CompareToNimbleText/>}/>
                    <Route path="*" element={<PageNotFound/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;

type Theme = 'light' | 'dark';

const themes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
        },
        menu: {
            menuContent: '#fbfcfd',
            icon: '#0098e5',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
    dark: {
        sidebar: {
            backgroundColor: '#0b2948',
            color: '#8ba1b7',
        },
        menu: {
            menuContent: '#082440',
            icon: '#59d0ff',
            hover: {
                backgroundColor: '#00458b',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};
const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

function Layout() {
    const docsService = new DocsService();
    const [collapsed] = React.useState(false);
    const [toggled, setToggled] = React.useState(false);
    const [broken, setBroken] = React.useState(false);
    const [theme] = React.useState<Theme>('light');
    const [hasImage] = React.useState(false);
    const menuItemStyles: MenuItemStyles = {
        root: {
            fontSize: '13px',
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({level}) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            height:'30px',
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({open}) => ({
            fontWeight: open ? 600 : "inherit"
        }),
    };

    return (
        <>
        <div className="header-menu bg-light bg-gradient w-100 p-2 d-flex justify-content-between align-items-center">
            {broken &&
                <button className="btn btn-primary  "
                        onClick={() => setToggled(!toggled)}>
                    <i className={'fa fa-bars'}/>
                </button>
            }
            <a href={AppRoutes.home + "#home"}
               className="d-inline-block text-center  text-decoration-none" title={Globals.AppTitle}>
               <span className={'sr-only'}>{Globals.AppTitle}</span>
                <img src={'textforge-text.svg'} style={{height:'55px'}} className={''} alt="TextForge"/>
            </a>
            <div>


                <Button className={'btn btn-primary'} href={AppRoutes.app} target="_blank">Start app now!</Button>
            </div>

        </div>
            <div className={'wrapper'}>

        <div className=" d-flex align-items-stretch">
            <Sidebar id={'sidebar'} className={''} breakPoint={"lg"}
                     toggled={toggled}
                     collapsed={collapsed}
                     onBreakPoint={setBroken}
                     backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
                     onBackdropClick={() => setToggled(false)}
            >
                <div className={'d-flex py-4 sticky-top overflow-y-auto position-relative flex-column'}>
                        <div>
                            <Menu menuItemStyles={menuItemStyles}>
                                <MenuItem

                                    component={<a href={AppRoutes.home + "#introduction"}
                                                  onClick={() => setToggled(!toggled)}/>}>Introduction</MenuItem>
                                <MenuItem component={<a href={AppRoutes.home + "#privacy"}
                                                        onClick={() => setToggled(!toggled)}/>}> Privacy </MenuItem>
                                <MenuItem component={<a href={AppRoutes.home + "#inputSettings"}
                                                        onClick={() => setToggled(!toggled)}/>}> Input
                                    settings </MenuItem>
                                <MenuItem component={<a href={AppRoutes.home + "#variables"}
                                                        onClick={() => setToggled(!toggled)}/>}> Variables </MenuItem>
                                <MenuItem component={<a href={AppRoutes.home + "#filters"}
                                                        onClick={() => setToggled(!toggled)}/>}> Filters </MenuItem>
                                <MenuItem component={<a href={AppRoutes.home + "#functions"}
                                                        onClick={() => setToggled(!toggled)}/>}> Functions </MenuItem>
                                <MenuItem
                                    component={<a href={AppRoutes.home + "#expressions"}
                                                  onClick={() => setToggled(!toggled)}/>}> Expressions </MenuItem>
                                <SubMenu label="Reference">
                                    {docsService.categories.map((category) => (
                                        <MenuItem key={category} component={<a
                                            href={AppRoutes.home + '#' + category}
                                            onClick={() => setToggled(!toggled)}/>}> {category}</MenuItem>
                                    ))}
                                </SubMenu>
                            </Menu>
                        </div>
                    </div>
                </Sidebar>


                <div id="content" className="p-4 p-md-5 pt-5">

                    <Outlet/>
                </div>
            </div>
        </div>
        </>
    );
}

