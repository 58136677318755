import React from "react";
import {Table} from "react-bootstrap";
import {Globals} from "../App";

export default function CompareToNimbleText() {

    return (
        <div>
            <h1 id={'home'}>{Globals.AppTitle} compared with NimbleText</h1>
            <div className={'my-5'}>
                <p className="lead">{Globals.AppTitle} is NimbleText on steroids.</p>
            </div>
            <div className={'my-5'}>
                <Table>
                    <thead>
                    <tr>
                        <th>Feature</th>
                        <th>NimbleText web</th>
                        <th>NimbleText</th>
                        <th>{Globals.AppTitle}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Transform CSV</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
}