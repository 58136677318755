import {HLJSApi, Language, Mode} from "highlight.js";

function siltext(hljs: HLJSApi) {

  const STRING: Mode = {
    scope: 'string',
    variants: [
      {begin: /'/, end: /'/},
      {begin: /"/, end: /"/},
    ]
  };

  const NUMBER: Mode = {
    scope: 'number',
    match: /\d+(\.\d+)?/
  };

  const VARIABLE: Mode = {
    scope: 'variable',
    match: /\$h?-?[0-9a-zA-Z]+/
  };

  const OPERATOR: Mode = {
    scope: 'operator',
    variants: [
      {match: /[+\-/%*]/},
      {match: /\|/},
      {match: /(and|or|not|&&|\|\|)/},
      {match: /==|!=|<|>|>=|<=/},
      {match: /\||~|:|\.{1,2}|\?{1,2}/},
      {match: /[(),]/},
    ],
  };

  const IDENTIFIER: Mode = {
    scope: 'identifier',
    match: /([a-z]|[A-Z])([a-zA-Z0-9_])*/,
  };

  const EXPR_TAG: Mode = {
    scope: 'expr-tag',

    begin: /\{\{/,
    end: /}}/,
    beginScope: "delimiter-tag",
    endScope: "delimiter-tag",
    contains: [
      'self',
      VARIABLE,
      OPERATOR,
      NUMBER,
      STRING,
      IDENTIFIER,
    ]
  };

  const lang: Language = {
    name: 'SilText',
    case_insensitive: true,
    contains: [
      VARIABLE,
      EXPR_TAG,
    ]
  };

  return lang;
}

export default siltext;
