import React, {useMemo} from "react";
import {Globals} from "../App";
import {Button, Col, Row, Table} from "react-bootstrap";
import {AppRoutes} from "../AppRoutes";
import {Code} from "../components/code/code";
import {Link} from "react-router-dom";
import {Example} from "../components/example/example";
import {DocsReference, DocsService} from "../services/docs.service";


export default function Docs() {

    const docsService = useMemo(() => new DocsService(), []);

    const [search, setSearch] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (search) {
            setSearchResults(docsService.search(search));
        }
    }, [search, docsService]);

    return (
        <>

            <h2 id="introduction">Introduction</h2>
            <p>
                If Excel, NimbleText, Find/Replace and Regular Expressions take steroids
                and have a baby, she would be called {Globals.AppTitle}.
            </p>
            <p>
                {Globals.AppTitle} lets you generate database scripts, code fragments, emails or any type of
                content really
                by
                applying
                a transformation template to each row of the input. This can be CSV data but also a plain text
                rows.
                It's
                heavily inspired by the excellent tool <Link to="https://nimbletext.com"
                                                             target="_blank">NimbleText</Link> and those
                who are familiar with NimbleText will also be familiar with {Globals.AppTitle}.
            </p>
            <p>If you love NimbleText so much, why go through the hassle of building this tool? First,
                NimbleText is not available on Linux, and the web version does not support all the features of
                the desktop version. Second, I need some extra filters and features that NimbleText does not
                offer. Finally, I have a longstanding interest in text processing principles, and building a
                tool like this is a great way to fulfill that interest.</p>
            <p>I believe {Globals.AppTitle} is NimbleText on steroids: it offers many more filters and
                functions, a full feature set in the online version, and a better user experience with
                autocompletion, code highlighting, and integrated references.</p>
            <div className={'mb-4'}>
                <Button variant={'link'} href={AppRoutes.compareToNimbleText}>Check full comparison</Button>
            </div>

            <p>
                The template let's you reference values (cells) in the input row using variables.
                and apply filters and functions and expressions for transformations.
            </p>
            <h2 id={'privacy'}>Privacy</h2>
            <p>Input, pattern and output data is processed in your locally by your browser, no data is submitted
                to our servers.</p>


            {/*<Example title={'Example'} input={Globals.Input} pattern={Globals.Pattern} output={Globals.Output}/>*/}
            <h2 id={'inputSettings'}>Input Settings</h2>
            <p>
                The input settings are used to configure how the input and output are parsed and
                formatted.
            </p>
            <Table>
                <tbody>
                <tr>
                    <td>
                        <pre>Headers</pre>
                    </td>
                    <td>Does the input contain a header row?</td>
                </tr>
                <tr>
                    <td>
                        <pre>Trim</pre>
                    </td>
                    <td>Trim whitespaces around the columns of the input rows. Output can be trimmed using the
                        trim filter.
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre>Delimiter</pre>
                    </td>
                    <td>The character that separates the columns in the input.</td>
                </tr>
                <tr>
                    <td>
                        <pre>Line ending</pre>
                    </td>
                    <td>The character that separates rows in the in the input.
                        Possible values:<br/>
                        <strong>\n</strong> (common in Unix and Unix-like systems, including Linux and
                        macOS)<br/>
                        <strong>\r</strong> (common in older Mac systems)<br/>
                        <strong>\r\n</strong> (common in Windows systems)
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre>Skip empty lines</pre>
                    </td>
                    <td>Ignore empty lines in the input.
                    </td>
                </tr>
                </tbody>
            </Table>

            <h2 id="variables">Variables</h2>
            <p>
                Use variables to write (echo/print) parts of your input to the output. {Globals.AppTitle} comes
                with a set of variables you can use to build your templates. Variables always start with a $
                (dollar) sign.
                Variables can be transformed using filters and functions.
            </p>
            <Table>
                <tbody>
                <tr>
                    <td>
                        <pre>$row</pre>
                    </td>
                    <td>The text of the entire current row including the line ending.</td>
                </tr>
                <tr>
                    <td>
                        <pre>$0, $1, $2, etc</pre>
                    </td>
                    <td>The text of the column at the specified index (numbering starts at 0).</td>
                </tr>
                <tr>
                    <td>
                        <pre>$-1, $-2, $-3, etc</pre>
                    </td>
                    <td>The text of the column at the specified index but starting at the end of the row.</td>
                </tr>
                <tr>
                    <td>
                        <pre>$h0, $h1, $h2, etc</pre>
                    </td>
                    <td>The header text at the specified index (numbering starts at 0).
                        <br/>
                        <strong>Note:</strong> Header variables are only supported in CSV-mode and only when the
                        headers option is
                        true.
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre>$h-1, $h-2, $h-3, etc</pre>
                    </td>
                    <td>The header text at the specified index but starting at the end of the row.
                        <br/>
                        <strong>Note:</strong> Header variables are only supported in CSV-mode and only when the
                        headers option is
                        true.
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre>$index</pre>
                    </td>
                    <td>The current row number (first row is $index = 0).</td>
                </tr>
                <tr>
                    <td>
                        <pre>$index1</pre>
                    </td>
                    <td>The current row number (first row is $index = 1).</td>
                </tr>
                <tr>
                    <td>
                        <pre>$rowNum</pre>
                    </td>
                    <td>Alias for $index.</td>
                </tr>
                <tr>
                    <td>
                        <pre>$rowNumOne</pre>
                    </td>
                    <td>Alias for $index1.</td>
                </tr>

                <tr>
                    <td>
                        <pre>$delimiter</pre>
                    </td>
                    <td>The character that separates the columns in the input.</td>
                </tr>
                <tr>
                    <td>
                        <pre>$rowDelimiter</pre>
                    </td>
                    <td>The character that separates the lines in the input.</td>
                </tr>

                <tr>
                    <td>
                        <pre>$fieldCount</pre>
                    </td>
                    <td>The number of fields in the current row.</td>
                </tr>

                <tr>
                    <td>
                        <pre>$template</pre>
                    </td>
                    <td>The value of the transformation template.</td>
                </tr>

                <tr>
                    <td>
                        <pre>$pattern</pre>
                    </td>
                    <td>Alias for $template.</td>
                </tr>
                </tbody>
            </Table>

            <p>
                Variables are can be used directly in the pattern or transformed using filters and functions.
            </p>
            <Example title={'Example'} input={`Employee, Employee since, Yearly Salary, Sales, sales target
Sally, 2024-01-01, 65000, 156000, 175000
Wilton,  2022-04-30, 70000, 175000, 165000`} pattern={'Hi $0, your $h4 is $$4.'} output={`
Hi Sally, your sales target is 175000
Hi Wilton, your sales target is 165000
`}/>

            <p className={'alert alert-info'}>
                When transforming variables in the pattern, they should be enclosed in double curly braces.
            </p>


            <h2 id={"filters"}>Filters</h2>
            <p>
                Filters can be used to transform variables or data generated by functions. Filters are separated
                from the
                variable by a pipe symbol (|). Multiple filters can be chained. The output of one filter is
                applied to the next.
            </p>

            <p>
                The following example takes the absolute value of the variable $0 and rounds it to the nearest
                integer:
                <Code code={`{{ $0|abs|round }}`}/>
            </p>
            <p>Filters that accept arguments have parentheses around the arguments. This example formats
                the variable $0 as a currency:
                <Code code={`{{ "123.4567"|number_format('N2') }}`}/>
            </p>
            <p>Check the full list of <Link to={AppRoutes.docs}>filters</Link> here.</p>

            <h2 id="functions">Functions</h2>
            <p>
                Functions can be used to generate data. Functions are called by their name, optionally followed
                by parentheses (()) if they have arguments.
            </p>
            <p>
                For instance, the uuid_v4 function generates a random UUID:
            </p>
            <Code code={`{{ uuid_v4 }}`}/>
            <p>
                The output of a function can be transformed using filters. For example, the following snippet
                can be used to output the current year:
            </p>
            <Code code={`{{ now|year }}`}/>

            <p>
                A full list of functions can be found <Link to={AppRoutes.docs}>functions</Link> here.
            </p>

            <h2 id={"expressions"}>Expressions and tests</h2>

            <p>
                Expressions can be used to perform calculations, comparisons and logical operations.
            </p>
            <p>Arithmetic expressions like * / + and - can be used to perform calculations.</p>
            <Example title={'Example'} input={`Employee, Employee since, Yearly Salary, Sales, Sales target
Sally, 2024-01-01, 65000, 156000, 175000
Wilton,  2022-04-30, 70000, 175000, 165000`} pattern="Hi $0, your bonus this year is {{ $2 * 0.1 }}." output={`
Hi Sally, your bonus this year is $6500.
Hi Wilton, your bonus this year is $7000.`}/>

            <p>Relational expressions can be used in combination with Conditional (Ternary) expressions to
                compare two values and evaluate Boolean values .</p>
            <Example title={'Example'} input={`Employee, Employee since, Yearly Salary, Sales, Sales target
Sally, 2024-01-01, 65000, 156000, 175000
Wilton,  2022-04-30, 70000, 175000, 165000`}
                     pattern='Hi $0, your bonus this year is {{ $2 * ($4 >= $3 ? 0.2 : 0.1) }}.' output={`
Hi Sally, your bonus this year is $13000.
Hi Wilton, your bonus this year is $7000.
`}/>

            <p>Logical expressions `and`, `or` and `not` can be used in logical operations.</p>
            <Example title={'Example'} input={`Employee, Employee since, Yearly Salary, Sales, Sales target
Sally, 2024-01-01, 65000, 156000, 175000
Wilton,  2022-04-30, 70000, 175000, 165000`}
                     pattern="Hi $0, your bonus this year is {{ $2 * ($4 > $3 || now|date_diff_years(date_from_format($1, 'yyyy-MM-dd'))|floor > 1 ? 0.2 : 0.1) }}."
                     output={`
Hi Sally, your bonus this year is $13000. 
Hi Wilton, your bonus this year is $14000. 
`}/>

            <p>Tests can be used to check your input data.</p>
            <Example title={'Example'} input={`Employee, Employee since, Yearly Salary, Sales, Sales target
Sally, 2024-01-01, 65000, 156000, 175000
Wilton,  2022-04-30, 70000, 175000, 165000`}
                     pattern="Hi $0, your bonus this year is {{ $2 * ($4 > $3 || now|date_diff_years(date_from_format($1, 'yyyy-MM-dd'))|floor > 1 ? 0.2 : 0.1) }}. '"
                     output={`
Hi Sally, your bonus this year is $13000. 
Hi Wilton, your bonus this year is $14000. 
`}/>

            <h2 id={'reference'}>Reference</h2>
            <Row>
                <Col md={9}>
                    {docsService.categories.map((category) => (
                        <div key={category}>
                            <h3 id={category} className={'mb-3'}>{category}</h3>
                            <Row>
                                {docsService.filtersByCategory[category] && (
                                    <CategoryAccordion items={docsService.filtersByCategory[category]} title="Filters"/>
                                )}
                                {docsService.functionsByCategory[category] && (
                                    <CategoryAccordion items={docsService.functionsByCategory[category]}
                                                       title="Functions"/>
                                )}
                                {docsService.testsByCategory[category] && (
                                    <CategoryAccordion items={docsService.testsByCategory[category]} title="Tests"/>
                                )}
                            </Row>
                        </div>
                    ))}
                </Col>
                <Col>
                    <div className={'docs-sidebar'}>
                        <input type="text" className={'form-control searchbar'} value={search}
                               onChange={(e) => setSearch(e.target.value)}
                               placeholder="Search filter, function or tests"/>

                        {search &&
                            <section className="docs_search_results bg-light p-3 rounded shadow">
                                {searchResults.length > 0 &&
                                    <div>
                                        {searchResults.map((doc: any, index) => {
                                            return (
                                                <div key={index}>
                                                    <a href={AppRoutes.home + "#" + doc.name}
                                                       onClick={() => setSearch('')}
                                                       className={'text-decoration-none text-dark'}>{doc.name}</a>

                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                {searchResults.length === 0 &&
                                    <span>No results found</span>
                                }
                            </section>
                        }
                        <>
                            {docsService.categories.map((category) => (
                                <>

                                    <h6 className={'my-2'}>{category}</h6>
                                    {docsService.filtersByCategory[category] && (
                                        <SideBarMenu items={docsService.filtersByCategory[category]}
                                                     title="Filters"/>
                                    )}
                                    {docsService.functionsByCategory[category] && (
                                        <SideBarMenu items={docsService.functionsByCategory[category]}
                                                     title="Functions"/>
                                    )}
                                    {docsService.testsByCategory[category] && (
                                        <SideBarMenu items={docsService.testsByCategory[category]} title="Tests"/>
                                    )}

                                </>
                            ))}
                        </>

                    </div>
                </Col>
            </Row>
        </>
    )
        ;
}

function SideBarMenu({items}: { items: DocsReference[], title: string }) {
    return (
        <>
            {items.map((item) => (
                <div key={item.name}>
                    <a href={AppRoutes.home + "#" + item.name}
                       className={'text-decoration-none text-dark'}>{item.name}</a>
                </div>
            ))}
        </>
    );
}

function CategoryAccordion({items}: { items: DocsReference[], title: string }) {
    return (
        <Col xs={12}>
            {/*<h4 className={'my-4'}>{title}</h4>*/}
            <div>
                {items.map((item) => (
                    <div key={item.name} id={item.name}>
                        <div className={'pt-2 pb-4'}>
                            <h3 className={''}>{item.name}</h3>
                            <p>
                                {item.description}
                            </p>
                            <hr/>
                            {item.sig}
                            <hr/>

                            {item.params.map((param) => (
                                <div key={param.name}>
                                    <strong>{param.name}</strong>: {param.comment}
                                </div>
                            ))}

                            <div>

                                {item.example && (
                                    <Code code={item.example}/>
                                )}
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </Col>
    );
}
