import React, {useState} from "react";
import './example.scss'
import hljs from 'highlight.js';


type ExampleProps = {
    title:string
    input:string
    pattern:string
    output:string
}
export const Example: React.FC <ExampleProps>= ({input, pattern,output}) =>{
    const defaultHeight = "1.5"; // Set your default height here

    const [inputHeight] = useState(defaultHeight);
    const [patternHeight] = useState(defaultHeight);
    const [outputHeight] = useState(defaultHeight);

    const patternHtml = hljs.highlight(pattern,{language: 'siltext'}).value;

    return (
        <div className="osx-window text-start">
            <div className="osx-window-title">EXAMPLE</div>
            <pre
                className="editor"
                style={{ minHeight: `calc(${inputHeight}rem + 6px * 2)` }}
            >
        <code>{input}</code>
      </pre>
            <div className="divider"></div>
            <pre
                className="editor"
                style={{ minHeight: `calc(${patternHeight}rem + 6px * 2)` }}
                dangerouslySetInnerHTML={{ __html: patternHtml }}
            ></pre>
            <div className="divider"></div>
            <pre
                className="editor"
                style={{ minHeight: `calc(${outputHeight}rem + 6px * 2)` }}
            >
        <code>{output}</code>
      </pre>
        </div>
    );
}