import React from "react";
import './code.scss'
import hljs from 'highlight.js';


type CodeProps = {
    code: string
}
export const Code: React.FC<CodeProps> = ({code}) => {
    const example = code ? code.replaceAll(/```(ts\n)?/gm, '') : null;
    const codeHtml = example ? hljs.highlight(example, {language: 'siltext'}).value : null;

    return (
        <div className="code-block">
            {codeHtml &&
                <code dangerouslySetInnerHTML={{__html: codeHtml}}></code>
            }
        </div>
    );
}