import React from "react";
import {Col, Row} from "react-bootstrap";
import {Globals} from "../App";
import {Example} from "../components/example/example";
import Docs from "./docs";

export default function Home() {


    return (
        <div>
            <h1 id={'home'}>{Globals.AppTitle}</h1>
            <div className={'my-5'}>
                <p className="lead">Transform CSV effortlessly.</p>
            </div>
            <div className={'my-5'}>
                <Row>
                    <Col>
                        <Example title={'Example'} input={Globals.Input} pattern={Globals.Pattern}
                                 output={Globals.Output}/>
                    </Col>
                </Row>
            </div>

            <div className={'my-5'}>
                <Docs/>

            </div>
        </div>
    )
        ;
}